//
// Request
//

.request-page {
  background-size: cover;
  background-position: center center;
  font-family: Poppins, Helvetica, 'sans-serif';
  .content-fluid {
    .card {
      max-width: 380px;
      width: 100%;
      min-width: 100px;
      .card-body {
        .card-top {
          img {
            max-width: 140px;
            max-height: 35px;
            object-fit: cover;
          }
          svg {
            fill: #707070;
            color: #707070;
          }
        }
        & > img {
          object-fit: cover;
          width: 100%;
        }
        h2 {
          color:#161616;
          font-weight: 500;
        }
        p {
          text-align: justify;
          color: #333;
          font-size: .9rem;
        }
        .pricing-container {
          color:#161616;
          font-weight: 600;
          font-size: 2rem;
          svg {
            font-size: 2rem;
          }
          & > span:not(.pe-2):not([aria-busy]) {
            display: inline-flex;
            flex-direction: column;
            flex-grow: 1;
            .pricing {
              line-height: 1.8rem;
            }
            .sub-repeat {
              font-size: 0.9rem;
              width: 100%;
              color: #707070;
              font-weight: 400;
            }
          }
        }
        .payment__icons {
          gap: 10px;
          row-gap: 10px;
          grid-template-columns: repeat(3, 1fr);
            /*grid-template-rows: repeat(3, 1fr);*/
          grid-column-gap: 10px;
          grid-row-gap: 10px;
          & > div {
          }
          a {
            aspect-ratio: 1.14;
          }
        }
      }
    }
  }
  .secured__badge {
    align-items: center;
    bottom: 20px;
    display: flex;
    justify-content: center;
    margin: 0 auto 6px;
    opacity: .6;
    position: fixed;
    right: 30px;
    text-decoration: none!important;
    .secured__badge__container {
      align-items: center;
      background-color: #000;
      border-radius: 0 0 3px 3px;
      display: flex;
      justify-content: center;
      padding: 6px 12px;
      .secured__badge__text {
        color: #fff;
        font-size: 11px;
        font-weight: 600;
        margin-left: 5px;
      }
    }
  }
}