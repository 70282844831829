//
// Main
//

.end-min-10 {
  right: -10px;
}
.top-min-10 {
  top: -10px;
}
.bottom-min-10 {
  bottom: -10px;
}