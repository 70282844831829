.PhoneInputCountry {

  background-color: #f5f8fa;
  padding: 0.825rem 1.5rem;
  font-size: 1.15rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 1.5;
  color: #3f4254;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #f5f8fa;
  margin-right: 0;
}