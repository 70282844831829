//
// (react-)tiptap editor
//

.tiptapEditor {
  .titapbuttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    gap: 10px;
    span {
      min-height: calc( 35px + 1rem );
      width: 1px;
    }
    button {
      margin: .5rem 0;
      border-radius: 0.325rem !important;
      border: 1px solid transparent !important;
      padding: 5px;
      background: white;
      transition: all .3s ease;
      min-width: 35px;
      min-height: 35px;
      position: relative;
      input[type="color"] {
        position: absolute;
        opacity: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      &.is-active {
        border-color: #b1b4b8 !important;
      }
      &:hover {
        border-color: #eff2f5 !important;
      }
    }
  }

  &.tiptapSmall {
    .titapbuttons {
      gap: 5px;
      button {
        padding: 3px;
        min-width: 25px;
        min-height: 25px;
        font-size: 0.95rem;
      }
    }
  }
}
.ProseMirror {
  &:focus-visible {
    outline: none;
  }
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
  .image-resizer {
    display: inline-flex;
    position: relative;
    flex-grow: 0;
    .resize-trigger {
      position: absolute;
      right: -6px;
      bottom: -9px;
      opacity: 0;
      transition: opacity .3s ease;
      color: #3259a5;
    }
    &:hover .resize-trigger {
      opacity: 1;
    }
  }
}