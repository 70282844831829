//
// (react-)Dropzone
//

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  &:focus {
    border-color: #2196f3;
  }
  &.disabled {
    opacity: 0.6;
  }
}
.loadingimage > div {
  position: relative;
  z-index: 500;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    backdrop-filter: blur(3px);
  }
}
.isloaded > div{
  position: relative;
  &:hover {
    &:after {
      content: "✓";
      color: greenyellow;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      position: absolute;
      background: rgba(0, 0, 0, 0.05);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(3px);
    }
  }
}