//
// FormBuilder Plugin
//

.formbuilder {
  .form-group {
    transition: all .3s ease;
    border: 1px dotted var(--bs-gray-400);
    .form-field-handler {
      display: none;
      z-index: 1000;
      &:before {
        content: "";
        z-index: -1;
        position: absolute;

        background-color: transparent;
        left: -16px;
        height: 16px;
        width: 16px;
        border-top-right-radius: 8px;
        box-shadow: 8px 0 0 0 #3699ff;
      }
      &:after {
        content: "";
        z-index: -1;
        position: absolute;

        background-color: transparent;
        right: -16px;
        height: 16px;
        width: 16px;
        border-top-left-radius: 8px;
        box-shadow: -8px 0 0 0 #3699ff;
      }
    }
    .form-field-resizer {
      z-index: 1000;
      width: 5px;
      background: transparent;
      transition: all .3s ease;
      cursor: grab;
      cursor: col-resize;
      &:hover {
        background: rgba(0,0,0,0.2);
      }
    }
    &:hover {
      border: 1px dotted var(--bs-gray-800);
      .form-field-handler {
        display: flex;
      }
    }
    &.active,&.sortable-ghost {
      border-style: solid;
      border-color: #3699ff;
    }
    .empty-spacer {
        span {
          transform: rotate(-10deg);
          display: inline-block;
          opacity: 0.5;
        }
    }
  }
}