//
// (react-)Select
//

.currency-select {
  div[class*="singleValue"],
  #react-select-2-listbox{
    span {
      > i {
        color: #181c32 !important;
      }
    }
  }
  span[class*="indicatorSeparator"] {
    display: none;
  }
  .dropdown-caret{
    display: inline-block;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: #7e8299;
    font-size: 1.2em;
    margin-left: 0.7em;
    margin-right: 0.9em;
  }
}