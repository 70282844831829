.socialPicker {
  .socialItem {
    .fa-pencil {
      opacity: 0;
      margin-bottom: -10px;
      transition: all .4s ease;
    }
    &:hover {
      .fa-pencil {
        opacity: 1;
        margin-bottom: 0;
      }
    }
  }
}